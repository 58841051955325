import dayjs from "dayjs";
import {
	transformToPhone,
	toProperCase,
	transformToCurrency,
	formatPostalCode,
} from "@utils/transform";
import { getScoreRange } from "@utils/leads";

import type { LeadsListEdges } from "~/model/leads";
import { ProductType } from "@api/graphql/types";

export function makeLeadsExport(data?: LeadsListEdges) {
	const header = [
		"Full Name",
		"Phone Number",
		"Date Applied",
		"Email",
		"City",
		"Province",
		"Postal Code",
		"AVA Credit",
		"Credit Verified",
		"AVA Drive",
		"AVA Trade",
		"Trade Value",
		"Credit Range",
		"Company Name",
		"ReferralURL",
		"Status",
		"UTM Campaign",
		"UTM Content",
		"UTM Medium",
		"UTM Source",
		"S1",
		"S2",
		"S3",
		"S4",
		"S5",
	];

	const rows = [header.join(",")];
	data?.forEach(
		({
			node: {
				firstName,
				lastName,
				phoneNumber,
				createdAt,
				emailAddress,
				city,
				provinceCode,
				postalCode,
				avaApp,
				tradeApps,
				company,
				sourceUrl,
				state,
				leadCampaign,
				leadJourneys,
			},
		}) => {
			const tradeInValue = tradeApps?.nodes[0]?.tradeInValue;
			const creditRange = getScoreRange(avaApp);
			const driveLead = () => {
				if (
					leadJourneys.nodes?.find(
						(journey) =>
							journey?.product?.name === ProductType.DriveTool,
					)
				) {
					return true;
				}
				return false;
			};
			const isDriveLead = driveLead();

			const columns = [
				`${toProperCase(firstName)} ${toProperCase(lastName)}`,
				transformToPhone(phoneNumber ?? "", true),
				dayjs(createdAt).format("MM/DD/YYYY hh:mma"),
				emailAddress,
				city,
				provinceCode,
				formatPostalCode(postalCode),
				avaApp ? "YES" : "NO",
				avaApp?.creditVerified ? "YES" : "NO",
				isDriveLead ? "YES" : "NO",
				tradeApps?.nodes?.length ? "YES" : "NO",
				tradeInValue ? transformToCurrency(tradeInValue, 2) : "",
				creditRange,
				company?.name || "",
				sourceUrl,
				state,
				leadCampaign?.utmCampaign,
				leadCampaign?.utmContent,
				leadCampaign?.utmMedium,
				leadCampaign?.utmSource,
				leadCampaign?.s1,
				leadCampaign?.s2,
				leadCampaign?.s3,
				leadCampaign?.s4,
				leadCampaign?.s5,
			].map((v) => (typeof v === "string" ? JSON.stringify(v) : v));
			rows.push(columns.join(","));
		},
	);
	return rows.join("\n");
}
