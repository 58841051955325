import React, {
	Fragment,
	useEffect,
	useMemo,
	useRef,
	useLayoutEffect,
	useState,
} from "react";

import isNil from "lodash/isNil";

import { FiltersForm } from "@leads/LeadList/FiltersForm";
import { LeadsList } from "@leads/LeadList";
import { ProductFilter } from "@leads/LeadList/ProductFilter";
import { ExportCsvButton } from "@leads/LeadList/ExportCsvButton";

import { ViewComponent, createPrivateView } from "~/model/view/private";
import { TABLE_LIMIT } from "~/model/leads";
import { ActiveALSProduct } from "~/model/products";

import { useAuth } from "@api/auth";
import { useLeadsList, useLeadProducts } from "@api/leads";

import { captureException } from "@utils/sentry";
import { parseQuery, stringifyQuery } from "@utils/url";
import { getLeadFilterInput, ILeadsFilterParams } from "@utils/leads";
import { mp } from "@utils/mixpanel";

import ProductCta from "~/components/ProductCta";
import { PrimaryButton } from "~/components/Button/PrimaryButton";
import CompanyPanel from "~/components/CompanyPanel";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SidebarDrawer from "~/components/SidebarDrawer";
import CreateTestLeadButton from "@leads/components/CreateTestLeadButton";
import { Assignee } from "~/components/Assignee";

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
		overflow: "hidden",
	},
	secondaryContent: {
		height: "100%",
		overflowX: "auto",
	},
	companyPanel: {
		borderTop: `1px solid ${theme.palette.borderInternal.main}`,
	},
	filters: {
		display: "flex",
		justifyContent: "end",
		padding: "0px 24px 18px",
		gap: 6,
		[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
			justifyContent: "space-between",
		},
	},
}));

export const Leads: ViewComponent = ({ location, navigate }) => {
	const { selectedCompany, changeCompany, user } = useAuth();
	const styles = useStyles();

	const curCompany = useRef(selectedCompany?.id);
	useLayoutEffect(() => {
		if (curCompany.current !== selectedCompany?.id) {
			const query = stringifyQuery(
				{
					...parseQuery(location?.search || ""),
					next: "",
				},
				true,
			);
			curCompany.current = selectedCompany?.id;
			navigate(`?${query}`, {
				state: { skipRouteChangeEvent: true },
			});
			mp.register({ dealerId: selectedCompany?.id });
		}
	}, [selectedCompany?.id, location?.search, navigate]);

	const curQueryParams = useMemo(() => {
		const {
			next = "",
			creditVerified = "",
			dateFrom = "",
			dateTo = "",
			search = "",
			product = "",
			idVerified = "",
			priority = "",
			state = "",
			assignee = "",
			mode = "",
		} = parseQuery(location?.search || "") as Record<string, string>;

		return {
			next,
			creditVerified,
			dateFrom,
			dateTo,
			search,
			product,
			idVerified,
			priority,
			state,
			assignee,
			mode,
		};
	}, [location?.search]);

	const nextKey = curQueryParams.next;

	const curFilter = useMemo(
		() => getLeadFilterInput(curQueryParams),
		[curQueryParams],
	);

	const filterRef = useRef<ILeadsFilterParams>(curQueryParams);

	const {
		loading: isLeadsLoading,
		leads,
		page,
		error: queryError,
	} = useLeadsList(TABLE_LIMIT, curFilter, nextKey);

	const {
		productsIndex,
		productList,
		loading: isProductsLoading,
	} = useLeadProducts();

	useEffect(() => {
		if (queryError) {
			console.error(queryError);
			captureException(queryError?.graphQLErrors[0], {
				curFilter,
				nextKey,
			});
		}
	}, [curFilter, nextKey, queryError]);

	const selectedProduct = curQueryParams.product as ActiveALSProduct;
	const isProductLocked =
		selectedProduct && !productsIndex[selectedProduct].enabled;
	const showCompanyPanel = user?.companies && user.companies.length > 1;

	const [open, doOpen] = useState(false);

	const handleClose = () => {
		doOpen(false);
	};

	const filterAssignee = (event: React.ChangeEvent<{ value: unknown }>) => {
		const queryString = stringifyQuery(
			{ ...curQueryParams, assignee: event.target.value },
			true,
		);
		navigate(`?${queryString}`, {
			state: { skipRouteChangeEvent: true },
		});
	};

	const assigneeSelector = useMemo(() => {
		if (selectedCompany?.rawMeta?.forceAssignedFilter) {
			if (user?.autocorpAdmin || user?.companyAdmin) {
				return (
					<Assignee
						value={curQueryParams.assignee}
						handleChange={filterAssignee}
					/>
				);
			}
			return null;
		}
		return (
			<Assignee
				value={curQueryParams.assignee}
				handleChange={filterAssignee}
			/>
		);
	}, [selectedCompany?.rawMeta, user]);

	return (
		<Fragment>
			<ProductFilter
				curFilter={curQueryParams}
				productList={productList}
				loading={isProductsLoading}
			/>
			<Box
				display="flex"
				flexDirection="column"
				flexWrap="nowrap"
				height="100%"
			>
				{!isProductLocked && (
					<Box className={styles.filters}>
						{assigneeSelector}
						<CreateTestLeadButton />
						<PrimaryButton onClick={() => doOpen(true)}>
							Filters
						</PrimaryButton>
						<SidebarDrawer
							title="Filters"
							onClose={handleClose}
							open={open}
						>
							<FiltersForm
								navigate={navigate}
								curFilter={curQueryParams}
								filterRef={filterRef}
								onComplete={handleClose}
							/>
						</SidebarDrawer>
					</Box>
				)}
				<Box className={styles.contentContainer}>
					{showCompanyPanel && (
						<CompanyPanel
							collection={user.companies}
							placement={"inline"}
							className={styles.companyPanel}
							title={(item) =>
								item?.name || "!!UNKNOWN COMPANY!!"
							}
							subtitle={() => {
								const productType =
									curFilter.product || "GLOBAL";
								const leadCount =
									productsIndex[productType].count;
								return !isNil(leadCount)
									? `${leadCount} Leads`
									: "";
							}}
							selected={(item) =>
								item?.id === selectedCompany?.id
							}
							onSelect={(item) =>
								item?.id && changeCompany(item?.id)
							}
						/>
					)}
					{!isProductLocked ? (
						<LeadsList
							leads={leads}
							nextKey={nextKey}
							page={page}
							selectedProduct={selectedProduct}
							loading={isLeadsLoading}
							queryError={queryError}
							navigate={navigate}
							exportCsv={<ExportCsvButton filter={filterRef} />}
						/>
					) : (
						<Box className={styles.secondaryContent}>
							<ProductCta topic={selectedProduct} />
						</Box>
					)}
				</Box>
			</Box>
		</Fragment>
	);
};

export default createPrivateView(Leads, {
	title: "Leads",
	internalScroll: true,
	internalPad: true,
});
